export const deltaClientEvents = {
  generic: {
    button_pressed: 'button_pressed',
    screen_presented: 'screen_presented',
    screen_dismissed: 'screen_dismissed',
    flow_started: 'flow_started',
    flow_ended: 'flow_ended',
    message_presented: 'message_presented',
    message_dismissed: 'message_dismissed',
    session_started: 'session_started',
    session_ended: 'session_ended'
  },
  auth: {
    login_step_started: 'login_step_started',
    login_step_ended: 'login_step_ended',
    signup_step_started: 'signup_step_started',
    signup_step_ended: 'signup_step_ended'
  },
  applications: {
    apply_to_campaign: 'apply_to_campaign'
  }
};

export interface SchemaMapperType {
  [key: string]: { schema: object; schemaId: number };
}

export const screenNames = {
  auth: {
    login: 'main_login_screen',
    login_otp: 'login_enter_otp_screen',
    signup: 'main_signup_screen',
    signup_otp: 'signup_enter_otp_screen'
  }
};

export const flowNames = {
  auth: {
    login: 'login',
    signup: 'signup'
  }
};

export const buttonNames = {
  auth: {
    login: 'Log In',
    resend_code: 'Resend Code',
    continue: 'Continue',
    exit: 'Exit',
    join_now: 'Join now'
  }
};

export const reasonNames = {
  success: 'success',
  failure: 'failure'
};

export const resultNames = {
  success: 'success',
  failure: 'failure',
  cancelled: 'cancelled'
};

export const DELTA_SCREEN_ROUTE_NAME_MAPPER: {
  [key: string]: { screen_name: string; tab: string };
} = {
  [screenNames.auth.login]: {
    screen_name: screenNames.auth.login,
    tab: ''
  },
  [screenNames.auth.login_otp]: {
    screen_name: screenNames.auth.login_otp,
    tab: ''
  },
  [screenNames.auth.signup]: {
    screen_name: screenNames.auth.signup,
    tab: ''
  },
  [screenNames.auth.signup_otp]: {
    screen_name: screenNames.auth.signup_otp,
    tab: ''
  }
};
