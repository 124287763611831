import { useMutation } from '@tanstack/react-query';
import Fortress from '../../services/fortress/Fortress';
import translate from '../../utils/translate';
import { ERRORS } from '../../config/constants';
import userUpdaters from '../../api/updaters/user';
import useNavigation from '../use-navigation';
import useParams from '../use-params/useParams';
import authRequests from '../../api/auth';
import useToken from '../use-token';
import useLtxToken from '../use-ltx-token';
import Delta from '../../utils/wrappers/Delta';

type AuthenticateData = {
  email: string;
  password: string;
  campaign_direct_link?: string;
  invite_code?: string;
  ltx_id?: string;
};

const useAuthenticate = () => {
  const navigation = useNavigation();
  const params = useParams();
  const { setToken } = useToken();
  const { setLtxToken } = useLtxToken();

  const addCampaignCreatorMatch = useMutation({
    mutationKey: ['user', 'addCampaignCreatorMatch', params.campaign_direct_link],
    mutationFn: userUpdaters.addCampaignCreatorMatch
  });

  const onSubmit = async (
    authType: 'login' | 'register',
    email: string,
    onError: (error: string) => void
  ): Promise<boolean> => {
    try {
      const isEmailExists = await authRequests.checkEmailExists(email);
      if ((!isEmailExists && authType === 'login') || (isEmailExists && authType === 'register')) {
        onError(translate(`templates.auth.${authType}.errors.default`));
        return false;
      }
      await Fortress.generateOTP(email);
      return true;
    } catch (error: any) {
      onError(error.message);
      return false;
    }
  };

  const onAuthenticate = async (
    data: AuthenticateData,
    onError: (error: string) => void
  ): Promise<boolean> => {
    try {
      const { response, sessionData } = await authRequests.login(data);

      const tokenSaved = await setToken(sessionData);
      if (!tokenSaved) {
        return false;
      }
      Delta.setCreatorId(response.data.data.id);
      setLtxToken(data.password);
      if (params.campaign_direct_link) {
        addCampaignCreatorMatch.mutate(params.campaign_direct_link);
      }
      navigation.navigate({ screen: '', url: '/' });
      return true;
    } catch (error: any) {
      let errorMessageCustom = error.message;
      if (error.message === ERRORS.USER_SUSPENDED) {
        errorMessageCustom = translate('templates.auth.login.errors.suspended');
      } else if (error.response) {
        const { errors } = JSON.parse(error.response.request.response);
        errorMessageCustom = errors
          .map((err: any) => {
            return err.detail;
          })
          .join(',');
      }
      onError(errorMessageCustom);
      return false;
    }
  };

  return {
    onAuthenticate,
    onSubmit
  };
};

export default useAuthenticate;
