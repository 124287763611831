import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import useUserQuery from '../../hooks/queries/use-user-query';
import EmberIframe from '../../components/ember-iframe';
import Menu from '../../components/menu';

import styles from './MainRoutes.module.scss';
import Banner from '../../components/banner';
import profileFetchers from '../../api/fetchers/profile';
import Delta from '../../utils/wrappers/Delta';

function MainRoutes() {
  const [emberModalIsOpen, setEmberModalIsOpen] = useState(false);
  const { user: userData, isLoading: isLoadingUser } = useUserQuery({});

  useEffect(() => {
    const removeModalListener = EmberIframe.listen('modal', setEmberModalIsOpen);
    return removeModalListener;
  }, []);

  useEffect(() => {
    if (userData?.id) {
      Delta.setCreatorId(userData.id);
    }
  }, [userData]);

  return (
    <>
      <Banner
        emberModalIsOpen={emberModalIsOpen}
        userData={userData}
        isLoadingUser={isLoadingUser}
      />
      <div className={styles.appWrapper}>
        <Menu emberModalIsOpen={emberModalIsOpen} />
        <Routes>
          <Route path="*" element={<EmberIframe />} />
          {/* <Route path="/new-page" element={<NewPage />} /> */}
        </Routes>
      </div>
    </>
  );
}

export default MainRoutes;
