import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useParams } from 'react-router-dom';
import Analytics from '@popularpays/analytics';
import AppProviders from './src/AppProviders';
import EmberIframe from './src/components/ember-iframe';
import AuthRoutes from './src/routes/auth';
import './src/index.scss';
import MainRoutes from './src/routes/main';
import PublicAccessRoutes from './src/routes/public-access';
import useLogout from './src/hooks/use-logout';
import useLtxToken from './src/hooks/use-ltx-token';
import useTokenSessionInvalidated from './src/hooks/use-token-session-invalidated';
import useSetLaunchDarklyContext from './src/hooks/use-set-launch-darkly-context';
import NotFound from './src/views/not-found';
import ModalTermsOfService from './src/components/modal-terms-of-service';
import FlashMessagesService from './src/components/flash-messages-service';
import useNavigation from './src/hooks/use-navigation';
import raiseFlashMessage from './src/utils/raiseFlashMessage';
import useToken from './src/hooks/use-token';
import useZendeskScript from './src/hooks/use-zendesk-script';

EmberIframe.listen('changePageTitle', (title: string) => {
  document.title = title;
});

function App() {
  const { token, initialized } = useToken(true);
  const { ltxToken, ltxTokenInitialized } = useLtxToken(true);
  const navigation = useNavigation();
  const { toast_message } = useParams();
  const { logout } = useLogout();
  const location = useLocation();
  const { isLoading: isSessionInvalidatedLoading } = useTokenSessionInvalidated();
  const { flags, launchDarklyInitialized } = useSetLaunchDarklyContext();
  useZendeskScript('320c4ae8-8ac1-467d-9ac3-ea18ec237353');

  EmberIframe.listen('logOut', () => {
    logout();
    navigation.navigate({ screen: '', url: '/auth/login' });
  });

  useEffect(() => {
    if (toast_message) {
      raiseFlashMessage({ message: toast_message });
    }
  }, []);

  useEffect(() => {
    const pagePath = location.pathname + location.search;
    Analytics.dispatchPageView(pagePath);
  }, [location]);

  if (!initialized && !ltxTokenInitialized) {
    return null;
  }

  if (ltxToken && !token && isSessionInvalidatedLoading) {
    return null;
  }

  if (!launchDarklyInitialized) {
    return null;
  }

  return token && ltxToken ? <MainRoutes /> : <AuthRoutes />;
}

function WrappedApp() {
  // all of various providers should be wrapping the <App /> component in here
  return (
    <AppProviders>
      <>
        <Router>
          <Routes>
            {PublicAccessRoutes()}
            <Route path="/*" element={<App />} />
            <Route path="/not/found" element={<NotFound />} />
          </Routes>
          <ModalTermsOfService />
        </Router>
        <FlashMessagesService />
      </>
    </AppProviders>
  );
}

export default WrappedApp;
