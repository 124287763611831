import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import Analytics from '@popularpays/analytics';
import analyticsEvents from '../../lib/analyticsEvents';
import styles from './Menu.module.scss';
import MainMenu from './main-menu';
import SecondaryMenu from './secondary-menu';
import isMobile from '../../utils/identifyDevice';
import translate from '../../utils/translate';

const MENU_HIDE_ROUTES = ['/campaigns/*'];

function Menu({ emberModalIsOpen }: { emberModalIsOpen: boolean }) {
  const location = useLocation();

  const shouldHideMenu =
    isMobile() && MENU_HIDE_ROUTES.some((route: string) => matchPath(route, location.pathname));

  const trackEventClickMenuItem = (menuItemName: string, linkUrl: string, isOutbound = false) => {
    const event =
      menuItemName === translate('components.user-toolbar.support')
        ? analyticsEvents.SUPPORT.CLICK_SUPPORT
        : analyticsEvents.MAIN_NAVIGATION.CLICK_MENU;
    Analytics.dispatchEvent(analyticsEvents.MAIN_NAVIGATION.CLICK_MENU, {
      click_text: menuItemName,
      // location: 'protected-verified-account__nav',
      field_name: `[${translate('components.user-toolbar.account')}][${menuItemName}]`,
      link_url: linkUrl,
      outbound: isOutbound
    });

    if (event !== analyticsEvents.MAIN_NAVIGATION.CLICK_MENU) {
      Analytics.dispatchEvent(event, {
        click_text: menuItemName,
        // location: 'protected-verified-account__nav',
        field_name: `[${translate('components.user-toolbar.account')}][${menuItemName}]`,
        link_url: linkUrl,
        outbound: isOutbound
      });
    }
  };

  return (
    <div
      className={styles.menuContainer}
      style={{
        filter: emberModalIsOpen ? 'brightness(0.6)' : undefined,
        display: (emberModalIsOpen && isMobile()) || shouldHideMenu ? 'none' : undefined
      }}
      data-cy={{ isMobile }}>
      <div className={styles.logoContainer}>
        <img className={styles.logo} src="/assets/svg/logo-black.svg" alt="" />
      </div>

      <MainMenu trackEventClickMenuItem={trackEventClickMenuItem} />
      <SecondaryMenu trackEventClickMenuItem={trackEventClickMenuItem} />
    </div>
  );
}

export default Menu;
