import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AppFooter from '../../components/app-footer';
import { getEnv } from '../../config/environment';
import getStores from '../../utils/getStores';
import useParams from '../../hooks/use-params/useParams';
import SignUp from '../../views/auth/sign-up/SignUp';
import Login from '../../views/auth/login/Login';
import styles from './AuthRoutes.module.scss';
import isMobile from '../../utils/identifyDevice';
import { PopularPaysBanner } from '../../components/icons';

type AuthenticateCallback = () => void;

function AuthRoutes() {
  const { rootStore } = getStores('rootStore');
  const params = useParams();
  const isMobileDevice = isMobile();

  const [preloadedTransparentIframe, setPreloadedTransparentIframe] =
    useState<React.ReactNode | null>(null);

  useEffect(() => {
    setPreloadedTransparentIframe(
      <div className={styles.iframeWrapper}>
        <iframe src={`${getEnv().VITE_DOMAIN_HOST_IFRAME}/not/found`} title="Ember iFrame" />
      </div>
    );
  }, []);

  useEffect(() => {
    if (params.campaignId) {
      rootStore.setRedirectUrlAfterAuth(`/campaigns/${params.campaignId}/apply`);
    }
  }, []);

  return (
    <div className={styles.authNavigator}>
      <div className={styles.authForm}>
        {!isMobileDevice && (
          <div className={styles.popularPaysLogo}>
            <PopularPaysBanner width={170} height={45} />
          </div>
        )}
        <div className={styles.authFormContent}>
          <Routes>
            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/register" element={<SignUp />} />
            <Route path="/auth/*" element={<Navigate to="/auth/login" />} />
            <Route path="/" element={<Navigate to="/auth/login" />} />
            <Route path="/campaigns/:campaignId/apply" element={<Login />} />
            <Route path="*" element={<Navigate to="/not/found" />} />
          </Routes>
          {isMobileDevice && (
            <div className={styles.popularPaysLogo}>
              <PopularPaysBanner width={120} height={30} />
            </div>
          )}
          <AppFooter />
        </div>
      </div>
      <div className={styles.authMediaWrapper}>
        <img className={styles.authMedia} src="/assets/images/welcome.jpg" alt="auth page" />
      </div>
      {preloadedTransparentIframe}
    </div>
  );
}

export default AuthRoutes;
